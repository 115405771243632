import React from "react"
import { Carousel } from "react-bootstrap"
import PrevIcon from "./prevIcon"
import NextIcon from "./nextIcon"
import Dots from "./dots"
import { Link } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"

const Client = ({ item }) => (
  <div className="case-study">
    <div className="layer"></div>
    <LazyLoadImage className="d-block w-100" src={item.image} alt="" />
    <Carousel.Caption>
      <Link to={item.link}>
        <h3>{item.title}</h3>
        <p>{item.description}</p>
      </Link>
    </Carousel.Caption>
  </div>
)

const Feedback = ({ item }) => (
  <div className="testimonial">
    <div className="author-wrapper">
      <div className="p-0 author-info">
        <h6 className="author-name">{item.name}</h6>
        <h5 className="job-position labs-gradient">{item.jobPosition}</h5>
      </div>
    </div>
    <p className="testimonial-text">{item.feedback}</p>
  </div>
)

const LbsCarousel = ({ slides, type }) => {
  const [currentIndex, setCurrentIndex] = React.useState(0)

  const handleSelect = (selectedIndex, _) => {
    setCurrentIndex(selectedIndex)
  }

  const prevIndex = () =>
    setCurrentIndex((currentIndex - 1 + slides.length) % slides.length)

  const nextIndex = () => setCurrentIndex((currentIndex + 1) % slides.length)

  return (
    <>
      <div className={type === "feedback" ? "gradient-wrapper" : ""}>
        <Carousel
          onSelect={handleSelect}
          activeIndex={currentIndex}
          controls={false}
          indicators={false}
          interval={null}
        >
          {slides.map((item, index) => (
            <Carousel.Item key={index}>
              {type === "clients" ? (
                <Client item={item} />
              ) : (
                <Feedback item={item} />
              )}
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      <div className="selection-wrapper text-center">
        <PrevIcon onClick={prevIndex} />
        <Dots
          slides={slides}
          currentIndex={currentIndex}
          onSelectDot={index => setCurrentIndex(index)}
        />
        <NextIcon onClick={nextIndex} />
      </div>
    </>
  )
}
export default LbsCarousel
