export const CLIENTS = [
  {
    title: "Ermeo",
    description:
      "Ermeo offers a SaaS solution that helps plan, manage, optimize industrial equipment maintenance operations. Check out how we helped to build an offline-first cross platform mobile application.",
    image: "/images/case-studies/ermeo.jpg",
    link: "/case-study/ermeo",
  },
  {
    title: "Wiremo",
    description:
      "How we helped Wiremo to build an end-to-end customer reviews solution for ecommerce platforms.",
    image: "/images/case-studies/wiremo.jpg",
    link: "/case-study/wiremo",
  },
]
