import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import Navbar from "@components/navbar"
import Services from "@components/services"

import Footer from "@components/footer"
import LbsCarousel from "@components/carousel"
import { Row, Col } from "react-bootstrap"
import { CLIENTS } from "../constants/clients"
import { FEEDBACKS } from "../constants/feedbacks"
import Announcement from "@components/announcement"
import { Link } from "gatsby"
import { Location } from "@reach/router"
import { LazyLoadImage } from "react-lazy-load-image-component"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <header className="intro">
      
      <div className="container">
        <Navbar />
        <h1 className="labs-title pt-5 pt-lg-0">
          Build better software, <br />
          faster
          <span className="labs-blue">.</span>
        </h1>
        <h2 className="labs-slogan">
          Labs42 creates value for companies by delivering
          <br />
          high-quality custom software
        </h2>
        <br />
        <Location>
          {({ location }) => (
            <Link
              to="/services/"
              className="btn btn-primary"
              activeClassName={location.pathname === "/" ? "active" : ""}
            >
              Check our Services
            </Link>
          )}
        </Location>
        <a href="#services" className="btn btn-link">
          More about Labs42
        </a>
        <br />
        <div className="scroll-down-wrapper">
          <LazyLoadImage
            src="data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjUxMnB0IiB2aWV3Qm94PSItMTA1IDAgNTEyIDUxMi4wMDAwMiIgd2lkdGg9IjUxMnB0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Im0xNTAuNjAxNTYyIDBjLTgzLjA0Mjk2OCAwLTE1MC42MDE1NjIgNjcuNTU4NTk0LTE1MC42MDE1NjIgMTUwLjYwMTU2MnYyMTAuNzk2ODc2YzAgODMuMDQyOTY4IDY3LjU1ODU5NCAxNTAuNjAxNTYyIDE1MC42MDE1NjIgMTUwLjYwMTU2MiA4My4wNDI5NjkgMCAxNTAuNjAxNTYzLTY3LjU1ODU5NCAxNTAuNjAxNTYzLTE1MC42MDE1NjJ2LTIxMC43OTY4NzZjMC04My4wNDI5NjgtNjcuNTU4NTk0LTE1MC42MDE1NjItMTUwLjYwMTU2My0xNTAuNjAxNTYyem0xMTkuODgyODEzIDM2MS4zOTg0MzhjMCA2Ni4xMDE1NjItNTMuNzgxMjUgMTE5Ljg4MjgxMi0xMTkuODgyODEzIDExOS44ODI4MTItNjYuMTAxNTYyIDAtMTE5Ljg4MjgxMi01My43ODEyNS0xMTkuODgyODEyLTExOS44ODI4MTJ2LTIxMC43OTY4NzZjMC02Ni4xMDE1NjIgNTMuNzgxMjUtMTE5Ljg4MjgxMiAxMTkuODgyODEyLTExOS44ODI4MTIgNjYuMTAxNTYzIDAgMTE5Ljg4MjgxMyA1My43ODEyNSAxMTkuODgyODEzIDExOS44ODI4MTJ6bTAgMCIvPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Im0xNTAuNjA1NDY5IDEwMS44NTE1NjJjLTguNDg0Mzc1IDAtMTUuMzU5Mzc1IDYuODc1LTE1LjM1OTM3NSAxNS4zNTkzNzZ2NzAuNjk1MzEyYzAgOC40ODA0NjkgNi44NzUgMTUuMzU5Mzc1IDE1LjM1OTM3NSAxNS4zNTkzNzUgOC40ODA0NjkgMCAxNS4zNTkzNzUtNi44Nzg5MDYgMTUuMzU5Mzc1LTE1LjM1OTM3NXYtNzAuNjk1MzEyYzAtOC40ODQzNzYtNi44NzUtMTUuMzU5Mzc2LTE1LjM1OTM3NS0xNS4zNTkzNzZ6bTAgMCIvPjwvc3ZnPg=="
            className="icon-mouse"
            alt=""
          />
          <LazyLoadImage
            src="/images/icons/arrow.svg"
            className="arrow-long"
            alt=""
          />
        </div>
      </div>
    </header>
    <div className="wave-wrapper">
      <section className="dark-bg wwd-wrapper margin-top-rwd">
        <Services />
      </section>
      <div id="team" className="our-team-wrapper dark-bg">
        <div className="container">
          <h2 className="heading">
            Our team
            <span className="labs-blue heading-dot"> .</span>
          </h2>
          <p className="subheading">Meet the team behind Labs42</p>
          <div className="team-wrapper-row row">
            <div className="labs-member col-xs-12 col-sm-3 col-md-3 col-lg-3">
              <div className="member-thumbnail cursor-pointer">
                <LazyLoadImage src="/images/alex.jpg" alt="" />
              </div>

              <div className="member-title">
                <h5 className="twitter-handle labs-blue">
                  <a
                    href="https://twitter.com/asimionese"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="labs-blue"
                  >
                    @asimionese
                  </a>
                </h5>
                <h2 className="founder cursor-pointer">Alexandru Asimionese</h2>
                Managing Director
              </div>
              <br />
              <br />
            </div>
            <div className="labs-member col-xs-12 col-sm-3 col-md-3 col-lg-3">
              <div className="member-thumbnail cursor-pointer">
                <LazyLoadImage src="/images/dima.jpg" alt="" />
              </div>

              <div className="member-title">
                <h5 className="twitter-handle labs-blue">
                  <a
                    href="https://twitter.com/dimadeveatii"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="labs-blue"
                  >
                    @dimadeveatii
                  </a>
                </h5>
                <h2 className="founder cursor-pointer">Dumitru Deveatii</h2>
                Head of Engineering
              </div>

              <br />
              <br />
            </div>
            <div className="labs-member col-xs-12 col-sm-3 col-md-3 col-lg-3">
              <div className="member-thumbnail cursor-pointer">
                <LazyLoadImage src="/images/radu.jpg" alt="" />
              </div>

              <div className="member-title">
                <h5 className="twitter-handle labs-blue">
                  <a
                    href="https://twitter.com/proca_radu"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="labs-blue"
                  >
                    @proca_radu
                  </a>
                </h5>
                <h2 className="founder cursor-pointer">Radu Proca</h2>
                Technical Lead
              </div>

              <br />
              <br />
            </div>

            <div className="labs-member col-xs-12 col-sm-3 col-md-3 col-lg-3">
              <div className="member-thumbnail cursor-pointer">
                <LazyLoadImage src="/images/vadim.jpg" alt="" />
              </div>

              <div className="member-title">
                <h5 className="twitter-handle labs-blue">
                  <a
                    href="https://twitter.com/rvmdl"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="labs-blue"
                  >
                    @rvmdl
                  </a>
                </h5>
                <h2 className="founder cursor-pointer">Vadim Russu</h2>
                Technical Lead
              </div>

              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container our-talks-wrapper">
      <div className="container">
        <h2 className="text-uppercase mega-font">
          Trusted by the <span className="labs-gradient"> best</span>
        </h2>
        <Row className="clients pb-5 m-auto">
          <Col xs={12} md={{ span: 6 }} lg={3} className="mb-5">
            <LazyLoadImage src="/images/clients/wiremo.svg" alt="" />
          </Col>
          <Col xs={12} md={6} lg={3} className="mb-5">
            <LazyLoadImage src="/images/clients/gotenerife.svg" alt="" />
          </Col>
          <Col xs={12} md={6} lg={3} className="mb-5">
            <LazyLoadImage src="/images/clients/ermeo.svg" alt="" />
          </Col>

          <Col xs={12} md={6} lg={3} className="mb-5">
            <LazyLoadImage src="/images/clients/spotio.png" alt="" />
          </Col>

          <Col xs={12} md={6} lg={3} className="mt-4 mb-5">
            <LazyLoadImage src="/images/clients/hideipvpn.png" alt="" />
          </Col>
          <Col xs={12} md={6} lg={3} className="mt-4 mb-5">
            <LazyLoadImage src="/images/clients/brazilero.svg" alt="" />
          </Col>
          <Col xs={12} md={6} lg={3} className="mt-4 mb-5">
            <LazyLoadImage src="/images/clients/british-american.png" alt="" />
          </Col>
          <Col xs={12} md={6} lg={3} className="mt-4 mb-5">
            <LazyLoadImage src="/images/clients/transparensee.png" alt="" />
          </Col>
        </Row>

        <h3 className="heading text-center">
          Products we've built are being used by
        </h3>
        <Row className="clients py-5  m-auto">
          <Col xs={12} md={{ span: 6 }} lg={3} className="mb-3 mb-lg-0">
            <LazyLoadImage
              src="/images/clients-of-clients/renault.svg"
              alt=""
            />
          </Col>
          <Col xs={12} md={6} lg={3} className="mb-3 mb-lg-0">
            <LazyLoadImage src="/images/clients-of-clients/total.svg" alt="" />
          </Col>
          <Col xs={12} md={6} lg={3} className="mb-3 mb-lg-0">
            <LazyLoadImage
              src="/images/clients-of-clients/heineken.svg"
              alt=""
            />
          </Col>
          <Col xs={12} md={6} lg={3} className="mb-3 mb-lg-0">
            <LazyLoadImage src="/images/clients-of-clients/orano.svg" alt="" />
          </Col>
        </Row>
        <a
          aria-label="View case studies"
          href="#case-studies"
          className="btn btn-primary"
        >
          View case studies
          <svg
            enableBackground="new 0 0 32 32"
            height="32px"
            version="1.1"
            viewBox="0 0 32 32"
            width="32px"
            space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            className="arrow-icon arrow-down"
          >
            <path
              clipRule="evenodd"
              d="M32,16.009c0-0.267-0.11-0.522-0.293-0.714  l-9.899-9.999c-0.391-0.395-1.024-0.394-1.414,0c-0.391,0.394-0.391,1.034,0,1.428l8.193,8.275H1c-0.552,0-1,0.452-1,1.01  s0.448,1.01,1,1.01h27.586l-8.192,8.275c-0.391,0.394-0.39,1.034,0,1.428c0.391,0.394,1.024,0.394,1.414,0l9.899-9.999  C31.894,16.534,31.997,16.274,32,16.009z"
              fill="#fff"
              fillRule="evenodd"
              id="Arrow_Forward"
            ></path>
          </svg>
        </a>
      </div>
      <div>
        <br />
        <div className="video-wrapper pt-6" id="case-studies">
          <h2 className="heading text-center">
            Browse amazing client stories
            <span className="labs-blue heading-dot"> .</span>
          </h2>

          <h5 className="gray text-center mb-5">
            We've delivered dozens of successful projects. Here are some of the
            highlights.
          </h5>

          <LbsCarousel slides={CLIENTS} type="clients" />
        </div>
      </div>
    </div>
    <div className="www-wrapper">
      <div className="container">
        <h2 className="heading">
          See real reviews from clients
          <span className="labs-blue heading-dot"> .</span>
        </h2>
        <p className="subheading">
          We are committed to doing great work on every project. Client reviews
          help us improve.
        </p>
        <div className="row testimonials-wrapper justify-content-md-center">
          <LbsCarousel type="feedback" slides={FEEDBACKS} />
        </div>
      </div>
    </div>
    <Footer />
  </Layout>
)

export default IndexPage
