import React from "react"

const Dots = ({ onSelectDot, slides, currentIndex }) => {
  return (
    <>
      {slides.map((_, index) => (
        <div
          key={index}
          className={`circle ${index === currentIndex ? "selected" : ""}`}
          role="button"
          aria-hidden="true"
          onClick={() => onSelectDot(index)}
        />
      ))}
    </>
  )
}

export default Dots
